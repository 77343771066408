@import "utils/libs";
@import "utils/vars";
@import "utils/mixins";
@import "utils/fonts";

 :root {
	--color-black: #000000;
	--color-white: #ffffff;
	--color-orange: #FF5C00; }

html {}

body {
	font-family: $base-font-family;
	font-weight: 400;
	font-size: 18px;
	color: #fff;
	scroll-behavior: smooth;
	text-rendering: optimizeSpeed;
	min-width: 300px;
	background: #111827 url(../images/content/bg.svg) no-repeat 50% 50%;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
	max-width: 100vw;
	padding-top: 90px;
	position: relative;
	background-size: cover;

	@include scrollbars(10px, #fff, transparent);

	@include tablet {
		padding-top: 40px; }

	@include mobile {
		padding-bottom: 40px; }

	&.fixed {
		overflow: hidden; } }

*,
*::before,
*::after {
	box-sizing: border-box; }

ul,
ol {
  list-style: none;
  padding: 0; }

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
	margin: 0; }

a:not([class]) {
	text-decoration-skip-ink: auto; }

img {
  max-width: 100%;
  display: block; }

input,
button,
textarea,
select {
	font: inherit;
	width: 100%; }

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important; } }

audio,
canvas,
iframe,
img,
svg,
video,
textarea {
	vertical-align: middle; }

textarea {
	resize: none; }

section {
	position: relative; }

input,
select,
button {
	outline: none; }

.d-f {
	display: flex; }

.a-c {
	align-items: center; }

.j-c {
	justify-content: center; }

a {
	color: #8E2828;

	&:hover {
		text-decoration: none; } }

p {
	margin-bottom: 5px; }

p + h3 {
	margin-top: 25px; }

.btn {
	font-weight: 400;
	font-size: 13px;
	line-height: 100%;
	color: #363636;
	border: 1px solid #fff;
	border-radius: 4px;
	padding: 12px 16px;
	display: inline-flex;
	align-items: center;
	text-decoration: none;
	transition-duration: $trds;
	font-family: $base-font-family;
	cursor: pointer;
	justify-content: center;
	background: #fff;

	&:hover {
		color: #fff; } }

.text--right {
	text-align: right; }

.text--orange {
	color: var(--color-orange); }

.wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100%; }

.container {
	margin: 0 auto;
	max-width: $content-size;
	width: 100%; }

.header {
	position: relative; }

.title {
	font-size: 75px;
	text-align: center;
	color: var(--color-orange);
	font-weight: 900;

	@include tablet {
		font-size: 64px; }

	@include mobile {
		font-size: 48px;
		margin-bottom: 25px; } }

.content {
	position: relative;

	.container {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include mobile {
			flex-direction: column; } } }

.content__img {
	width: 100%;
	max-width: 434px;

	@include mobile {
		max-width: 280px;
		margin-top: 50px;
		margin-bottom: 50px; }

	img {
		display: block;
		width: 100%;
		max-width: 434px; } }

.content__form {
	width: 370px;
	min-width: 370px;
	padding: 0 15px;
	display: flex;
	align-items: center;
	display: flex;
	flex-direction: column;

	@include tablet {
		width: 290px;
		min-width: 290px; } }

.form__title {
	font-size: 28px;
	font-weight: 700;
	text-align: center;
	margin-bottom: 30px;

	span {
		color: var(--color-orange); } }

.form {
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative; }

.form__label {
	display: block;
	margin-bottom: 10px; }

.form__input {
	font-family: $base-font-family;
	padding: 10px 15px;
	color: #fff;
	border: 1px solid #fff;
	background: transparent;
	font-size: 14px;
	width: 100%;
	border-radius: 20px; }

.form__select {
	font-family: $base-font-family;
	padding: 10px 15px;
	color: #fff;
	border: 1px solid #fff;
	background: transparent;
	font-size: 14px;
	width: 100%;
	border-radius: 20px;

	option {
		color: #000; } }

.form__btn {
	padding: 10px;
	color: #111827;
	border: 1px solid #fff;
	font-size: 18px;
	font-weight: 700;
	background: #fff;
	cursor: pointer;
	border-radius: 20px;
	transition-duration: .25s;
	margin-top: 10px;

	&:hover {
		color: #fff;
		background: transparent; } }

.form__message {
	position: absolute;
	left: 0;
	right: 0;
	bottom: -36px;
	text-align: center;
	font-size: 14px; }

.scroll {

	.open__nav {} }

.open__nav {
	width: 40px;
	height: 31px;
	position: relative;
	transform: rotate(0deg);
	transition: .5s ease-in-out;
	cursor: pointer;
	position: fixed;
	top: 29px;
	right: 10px;
	z-index: 5;
	padding: 5px;
	display: none;
	background: rgba(255,255,255,.75);
	border-radius: 5px;

	@include minMobile {
		top: 14px; }

	span {
		display: block;
		position: absolute;
		height: 4px;
		background: #8E2828;
		opacity: 1;
		left: 5px;
		width: calc(100% - 10px);
		transform: rotate(0deg);
		transition: .25s ease-in-out;

		&:nth-child(1) {
			top: 6px; }

		&:nth-child(2), &:nth-child(3) {
			top: 14px; }

		&:nth-child(4) {
			top: 22px; } }


	&.open {

		span {

			&:nth-child(1) {
				top: 5px;
				width: 0%;
				left: 50%; }

			&:nth-child(2) {
				transform: rotate(45deg); }

			&:nth-child(3) {
				transform: rotate(-45deg); }

			&:nth-child(4) {
				top: 23px;
				width: 0%;
				left: 50%; } } } }

.nav__mobile {
	position: fixed;
	background: #000;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 444;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	opacity: 0;
	visibility: hidden;
	transition-duration: $trds;
	flex-direction: column;
	overflow-x: auto;
	padding: 25px 15px;
	height: 100%;

	&.open {
		opacity: 1;
		visibility: visible; } }

.nav-mobile__item {

	& + & {
		margin-top: 25px; } }

.nav-mobile__link {
	text-decoration: none;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;

	@include mobile {
		font-size: 22px; } }

.nav__logo {
	height: 79px;
	width: 254px;
	overflow: hidden;
	margin-bottom: 30px;
	min-height: 79px;
	margin-top: 60px;

	svg, img {
		height: 79px;
		width: 780px;
		margin-left: -535px; } }

.nav-mobile__link {
	font-size: 22px;
	color: #fff;
	text-decoration: none; }

.nav-mobile-child__link {
	color: #fff;
	text-decoration: none;
	font-size: 20px; }

.nav-mobile-child__item {
	margin-top: 15px; }

.section__title {
	font-size: 42px;
	font-weight: 700;
	text-align: center;
	margin-bottom: 30px;

	span {
		color: var(--color-orange); } }

section {
	padding-top: 50px;
	padding-bottom: 50px; }

.add__wrap {
	display: flex;
	justify-content: space-between;

	@include mobile {
		flex-direction: column;
		align-items: center; } }

.add__zapravka {
	width: 100%;
	max-width: 370px;
	padding: 0 15px;

	@include mobile {
		width: 290px;
		min-width: 290px; } }

.add__partner {
	width: 100%;
	max-width: 370px;
	padding: 0 15px;

	@include mobile {
		width: 290px;
		min-width: 290px;
		margin-top: 50px; } }

.subtitle {
	font-size: 28px;
	font-weight: 700;
	text-align: center;
	margin-bottom: 30px;

	span {
		color: var(--color-orange); } }



.section__carousel {
    margin-top: 120px;

    @include laptop {
        margin-top: 60px; } }

.slider__list {
    min-height: 540px;
    position: relative;

    @include laptop {
        min-height: 410px; }

    @include tablet {
        min-height: 280px; }

    @include mobile {
        min-height: 180px; } }

.bee3D--nav__prev {
    background: none !important; }

.bee3D--nav__next {
    background: none !important; }

.slider__item {
    width: 840px !important;
    _opacity: .5;
    _transition-duration: .25s;
    _padding: 0 75px;
    height: 540px !important;
    margin-top: -270px !important;
    margin-left: -420px !important;

    @include laptop {
        width: 640px !important;
        height: 410px !important;
        margin-left: -320px !important;
        margin-top: -205px !important;
        _padding: 0 55px; }

    @include tablet {
        width: 440px !important;
        height: 280px !important;
        margin-left: -220px !important;
        margin-top: -140px !important;
        _padding: 0 45px; }

    @include mobile {
        width: 280px !important;
        height: 180px !important;
        margin-left: -140px !important;
        margin-top: -90px !important;
        _padding: 0 30px; }

    &.slick-center {
        _width: 1040px;
        opacity: 1;
        transform: scale(1.35); } }

.slider__wrap {

    .slick-track {
        _transition-duration: .25s;
        display: flex;
        align-items: center;
        padding: 75px 0; } }

.slider__arrow {
    position: absolute;
    top: 50%;
    margin-top: -25px;
    left: 0;
    right: 0;
    display: flex;
    z-index: 5; }

.slider-arrow__prev {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    left: 50% !important;
    margin-left: -600px;
    position: absolute;

    @include laptop {
        margin-left: -450px; }

    @include tablet {
        left: 25px !important;
        margin-left: 0; }

    svg, img {
        width: 50px;
        height: 50px; } }

.slider-arrow__next {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    right: 50% !important;
    margin-right: -600px;
    position: absolute;

    @include laptop {
        margin-right: -450px; }

    @include tablet {
        right: 25px !important;
        margin-right: 0; }

    svg, img {
        width: 50px;
        height: 50px; } }

.slider__link {
	display: block;
	position: relative;

	img {
		display: block;
		border-radius: 50px; } }

.slider__video {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);
	transition-duration: 0.25s;
	width: 100px;
	height: 100px;

	@include mobile {
			width: 30px;
			height: 30px; } }

//Модули
@import "modules/modules";
